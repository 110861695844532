import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog, Table, Textarea, Label, Button } from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft, FaPhoneAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { Card, Row, Col, Form } from 'react-bootstrap/';
import { FcMissedCall, FcApproval, FcCancel, FcPaid, FcRefresh, FcInTransit, FcAssistant } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as moment from "moment-timezone";
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    { field: "delete", name: "الغاء", options: { width: 80, filter: true, sort: true, } },

    { field: "profile", name: "تأكيد", options: { width: 80, filter: true, sort: true, } },


    { field: "more", name: "معلومات", options: { width: 80, filter: true, sort: true, } },
    { field: "whatsapp", name: "واتساب", options: { filter: true, sort: true, } },
    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            data: [],
            count: 0,
            selectedItems: [],
            spinPage: true,
            file1: [],
            file2: [],
            file3: [],
            file4: [],
            file5: [],
            price: '',
            size: 'non',
            notes: '',
            user_id: 0,
            role: 0,
            users: []

        };

    }
    add_call(id) {
        axios({
            method: 'post',
            url: host + `users/order/add/calls/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {


            this.componentDidMount("لايرد")
        }).catch((error) => {

        })

    }
    fixed(id) {
        axios({
            method: 'post',
            url: host + `users/order/fixed/calls/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {

        }).catch((error) => {

        })

    }

    fixed_delv(id) {
        axios({
            method: 'post',
            url: host + `users/order/fixed/delv/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {

        }).catch((error) => {

        })

    }
    componentDidMount() {
        if (cookies.get("token")) {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
            const urlParams = new URLSearchParams(window.location.search);
            const number = urlParams.get('number');
            axios.get(host + `users/auth/profile`, { headers: header })
                .then((response) => {
                    console.log(response.data.data.profile);
                    if (response.data.data.profile.isBan === true) {
                        window.location.href = '/login';
                    }
                    this.setState({ role: response.data.data.profile.role, user_id: response.data.data.profile.id })
                }).catch(() => {
                    window.location.href = '/login';
                })



            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
            let users = []
            axios.get(host + `users/calls/users/`, { headers: header })
                .then((response) => {
                    users = response.data.users
                    this.setState({ users: response.data.users })
                }).catch(() => {
                    window.location.href = '/login';
                })

            axios.get(host + `users/orders/search/check?phone=${number}`, { headers: header })
                .then(res => {
                    let arr = [];
                    console.log(res.data.data.orders);
                    for (let i = 0; i < res.data.data.orders.length; i++) {

                        if (res.data.data.orders[i].ordersFormItems) {
                            console.log('here');
                            arr.push(res.data.data.orders[i])
                        }


                    }
                    console.log(arr);
                    this.setState({
                        items: arr, spinPage: false, data: arr, count: arr.length
                    });
                })
                .catch(error => {
                    console.log(error)
                })
            this.setState({ users: users })
        } else {
            window.location.href = '/login';

        }
    }


    miss(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.post(host + `users/order/miss/${id}`, { headers: header })
            .then(response => {
                window.alert('تم التحديث')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    deleteSubscriptions(id, name, msg) {

        axios({
            method: 'post',
            url: host + 'users/web/orders/confirm',
            headers: { 'Authorization': cookies.get("token") },
            data: {
                status: 2,
                reject: msg,

                id: id,


            }
        }).then((response) => {
            window.alert(`تم الغاء الطلب رقم ${id} الطلب بأسم ${name}`)

            this.componentDidMount()
        }).catch((error) => {

            window.location.href = '/Error'
        })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })
    click_log(id, type) {

        axios({
            method: 'post',
            url: host + 'users/web/click/log',
            headers: { 'Authorization': cookies.get("token") },
            data: {
                status: type,
                id: id,
            }
        }).then((response) => {
            // window.alert(`تم الغاء الطلب رقم ${id} الطلب بأسم ${name}`)

            // this.componentDidMount()
        }).catch((error) => {

            window.location.href = '/Error'
        })
        console.log(id, type);
    }
    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4> معلومات الطلب </h4>



                                </div>


                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        {/* <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider> */}
                                        <Row>
                                            {this.state.data.map((item, index) => (

                                                <Col key={index}>
                                                    <Card style={{ margin: '10px', marginTop: "50px", border: "1px solid #c5320ddb", background: item.color }} >

                                                        <Card.Body>
                                                            <center>

                                                                <Card.Title>رقم الوصل</Card.Title>
                                                                <Card.Title>{item.id}</Card.Title>
                                                            </center>

                                                            <div>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الهاتف</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>واتساب </div></Table.TextHeaderCell>

                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><a href={`tel:${item.phone}`} onClick={() => {

                                                                                // this.click_log(item.id, 'phone')
                                                                            }}>{item.phone} </a></div></Table.TextCell>
                                                                            <Table.TextCell> <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><a onClick={() => {
                                                                                // this.click_log(item.id, 'whatssap')
                                                                                setTimeout(() => {
                                                                                    window.location.replace(`https://api.whatsapp.com/send?phone=964${item.phone.slice(1)}`);
                                                                                }, 500);

                                                                            }}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 25 }} /></a></div></Table.TextCell>
                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> مثبت الطلب </div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> رابط الحساب</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>  حالة الطلب</div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.user.name}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                                                                                onClick={() => window.location.href = item.account} /></div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> {item.status} </div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> اسم الزبون</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم البيج</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر الكلي</div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.page_name}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المادة</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                                    </Table.Head>
                                                                    <Table.Body height={100}>
                                                                        {item.ordersFormItems.map((item, i) =>
                                                                            <Table.Row key={i} >
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.components.name}</div></Table.TextCell>
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                            </Table.Row>
                                                                        )}
                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.government + " / " + item.city}</div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>تاريخ ترحيل الطلب  </div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >

                                                                            {
                                                                                item.printerOrders.length > 0 ? <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{moment(item.printerOrders[0].prints.createdAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT')}</div></Table.TextCell>
                                                                                    : <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{"لم يتم الترحيل الطلب"}</div></Table.TextCell>
                                                                            }


                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>
                                                                {
                                                                    item.orderCall.length > 0 ?
                                                                        <div>
                                                                            <Table>
                                                                                <Table.Head>
                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> المعالج</div></Table.TextHeaderCell>
                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> تاريخ تحويل الطلب </div></Table.TextHeaderCell>


                                                                                </Table.Head>
                                                                                <Table.Body height={100}>

                                                                                    <Table.Row >

                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.orderCall[0].user.name}</div></Table.TextCell>

                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>{moment(item.orderCall[0].createdAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT')}</div></Table.TextCell>

                                                                                    </Table.Row>

                                                                                </Table.Body>
                                                                            </Table>

                                                                            <Table>
                                                                                <Table.Head>
                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> التبليغ</div></Table.TextHeaderCell>
                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>   تم التحويل بواسطة </div></Table.TextHeaderCell>
                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>     عدد محاولات الايرد </div></Table.TextHeaderCell>


                                                                                </Table.Head>
                                                                                <Table.Body height={100}>

                                                                                    <Table.Row >

                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.calls}</div></Table.TextCell>
                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.orderCall[0].user_name}</div></Table.TextCell>

                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.call_count}</div></Table.TextCell>

                                                                                    </Table.Row>

                                                                                </Table.Body>
                                                                            </Table>
                                                                        </div>


                                                                        : <div></div>



                                                                }

                                                            </div>
                                                            <div style={{ justifyContent: 'center', alignItems: "center", display: "flex", margin: '10px' }}>

                                                                {
                                                                    this.state.role == 3 && item.is_send == false ?


                                                                        <Component initialState={{ isShown: false, items: [], from: new Date(), spin: true, value: "", msg: "", users: this.state.users }
                                                                        }>
                                                                            {({ state, setState }) => (
                                                                                <Pane>
                                                                                    <Dialog
                                                                                        isShown={state.isShown}
                                                                                        onCloseComplete={() => {
                                                                                            setState({ isShown: false })
                                                                                        }}

                                                                                        // hasFooter={false}
                                                                                        title={'تلبيغ'}
                                                                                        onConfirm={() => {



                                                                                            axios({
                                                                                                method: 'post',
                                                                                                url: host + 'users/send/call',
                                                                                                headers: { 'Authorization': cookies.get("token") },
                                                                                                data: {
                                                                                                    driver_phone: "",
                                                                                                    order_id: item.id,
                                                                                                    status: state.value,
                                                                                                    date: state.from
                                                                                                }
                                                                                            }).then((response) => {
                                                                                                window.alert('تم تحويل الطلب ')
                                                                                                setState({ isShown: false })
                                                                                            }).catch((error) => {

                                                                                            })


                                                                                        }}
                                                                                    >
                                                                                        <div style={{ direction: 'rtl' }} >
                                                                                            {!state.spin ?
                                                                                                <div>
                                                                                                    <div>
                                                                                                        <div>
                                                                                                            <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                                                                document.getElementById('dateDiv').style.display = 'none'
                                                                                                                setState({ value: "لايرد" })
                                                                                                            }} />
                                                                                                            <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                                                                document.getElementById('dateDiv').style.display = 'none'
                                                                                                                setState({ value: "شركة التوصيل" })
                                                                                                            }} />
                                                                                                            <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                                                                document.getElementById('dateDiv').style.display = 'none'
                                                                                                                setState({ value: "خطأ بالتجهيز" })
                                                                                                            }} />
                                                                                                            <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                                                                document.getElementById('dateDiv').style.display = 'none'
                                                                                                                setState({ value: "كاذب" })
                                                                                                            }} />
                                                                                                            <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                                                                        </div>

                                                                                                        <div>

                                                                                                            <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                                                                document.getElementById('dateDiv').style.display = 'flex'
                                                                                                                setState({ value: "مؤجل" })
                                                                                                            }} />
                                                                                                            <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                                                                        </div>
                                                                                                        <div id="dateDiv" style={{ display: "none" }}>
                                                                                                            <hr></hr>
                                                                                                            <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                                                            <DatePicker
                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                id='date1'
                                                                                                                selected={state.from}
                                                                                                                onChange={(e) => {
                                                                                                                    setState({ from: e })
                                                                                                                }}
                                                                                                            />

                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                                                                    <Spinner />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </Dialog>
                                                                                    <FaPhoneAlt onClick={() => {

                                                                                        setState({ isShown: true, spin: false })

                                                                                    }} style={{ cursor: 'pointer', color: '#d61124' }} />
                                                                                </Pane>
                                                                            )
                                                                            }
                                                                        </Component >




                                                                        : <div></div>
                                                                }

                                                                {
                                                                    item.orderCall.length > 0 ?
                                                                        <div>
                                                                            {
                                                                                this.state.role == 3 ?
                                                                                    <div style={{ display: 'flex' }}>

                                                                                        <Component initialState={{ isShown: false, from: new Date(), items: [], spin: true, value: "", msg: "" }
                                                                                        }>
                                                                                            {({ state, setState }) => (
                                                                                                <Pane>
                                                                                                    <Dialog
                                                                                                        isShown={state.isShown}
                                                                                                        onCloseComplete={() => {
                                                                                                            setState({ isShown: false })
                                                                                                        }}
                                                                                                        // hasFooter={false}
                                                                                                        title={'تلبيغ'}
                                                                                                        onConfirm={() => {
                                                                                                            let value = state.value;
                                                                                                            let date = ""
                                                                                                            if (value === 'مؤجل') {
                                                                                                                date = state.from;
                                                                                                                if (!date) {
                                                                                                                    window.alert('اختر تاريخ التأجيل')
                                                                                                                    return -1
                                                                                                                }

                                                                                                            }

                                                                                                            axios({
                                                                                                                method: 'post',
                                                                                                                url: host + `users/calss/change/${item.id}`,
                                                                                                                headers: { 'Authorization': cookies.get("token") },
                                                                                                                data: {
                                                                                                                    status: state.value,
                                                                                                                    date: date,

                                                                                                                }
                                                                                                            }).then((response) => {
                                                                                                                window.alert('تم التبليغ')
                                                                                                                setState({ isShown: false })


                                                                                                                this.componentDidMount()
                                                                                                            }).catch((error) => {

                                                                                                            })


                                                                                                        }}
                                                                                                    >
                                                                                                        <div style={{ direction: 'rtl' }} >
                                                                                                            <div>
                                                                                                                <div>
                                                                                                                    <div>
                                                                                                                        <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                                                                            document.getElementById('dateDiv').style.display = 'none'
                                                                                                                            setState({ value: "لايرد" })
                                                                                                                        }} />
                                                                                                                        <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                                                                            document.getElementById('dateDiv').style.display = 'none'
                                                                                                                            setState({ value: "شركة التوصيل" })
                                                                                                                        }} />
                                                                                                                        <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                                                                            document.getElementById('dateDiv').style.display = 'none'
                                                                                                                            setState({ value: "خطأ بالتجهيز" })
                                                                                                                        }} />
                                                                                                                        <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                                                                            document.getElementById('dateDiv').style.display = 'none'
                                                                                                                            setState({ value: "كاذب" })
                                                                                                                        }} />
                                                                                                                        <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                                                                                    </div>

                                                                                                                    <div>

                                                                                                                        <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                                                                            document.getElementById('dateDiv').style.display = 'flex'
                                                                                                                            setState({ value: "مؤجل" })
                                                                                                                        }} />
                                                                                                                        <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                                                                                    </div>
                                                                                                                    <div id="dateDiv" style={{ display: "none" }}>
                                                                                                                        <hr></hr>
                                                                                                                        <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                                                                        <DatePicker
                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                            id='date1'
                                                                                                                            selected={state.from}
                                                                                                                            onChange={(e) => {
                                                                                                                                setState({ from: e })
                                                                                                                            }}
                                                                                                                        />

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Dialog>
                                                                                                    <FcRefresh onClick={() => {
                                                                                                        setState({ isShown: true })

                                                                                                    }} style={{ cursor: 'pointer', margin: 25, color: '#d61124', fontSize: 40 }} />
                                                                                                </Pane>
                                                                                            )
                                                                                            }
                                                                                        </Component >

                                                                                        {
                                                                                            item.calls == "تم المعالجة" ? <div>

                                                                                                <Component initialState={{ isShown: false, notes: "" }}>
                                                                                                    {({ state, setState }) => (
                                                                                                        <Pane>
                                                                                                            <Dialog
                                                                                                                isShown={state.isShown}
                                                                                                                title="  تأكيد تسليم الطلب "
                                                                                                                intent="success"
                                                                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                                                                confirmLabel="تاكيد"
                                                                                                                cancelLabel='الغاء'
                                                                                                                onConfirm={() => {
                                                                                                                    this.fixed_delv(item.id)

                                                                                                                    window.alert('تم معالجة الطلب')

                                                                                                                    setState({ isShown: false })
                                                                                                                    this.componentDidMount()
                                                                                                                }}
                                                                                                            >
                                                                                                            </Dialog>
                                                                                                            <FcInTransit style={{ cursor: 'pointer', margin: 25, fontSize: 40 }}
                                                                                                                onClick={() => setState({ isShown: true })} />
                                                                                                        </Pane>
                                                                                                    )}
                                                                                                </Component>
                                                                                            </div> :
                                                                                                <div>

                                                                                                </div>

                                                                                        }
                                                                                        {
                                                                                            item.calls != "تم المعالجة" && item.calls != "تم التسليم" ? <div>
                                                                                                <Component initialState={{ isShown: false, notes: "" }}>
                                                                                                    {({ state, setState }) => (
                                                                                                        <Pane>
                                                                                                            <Dialog
                                                                                                                isShown={state.isShown}
                                                                                                                title=" معالجة الطلب "
                                                                                                                intent="success"
                                                                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                                                                confirmLabel="تاكيد"
                                                                                                                cancelLabel='الغاء'
                                                                                                                onConfirm={() => {
                                                                                                                    this.fixed(item.id)



                                                                                                                    window.alert('تم المعالجة')
                                                                                                                    setState({ isShown: false })


                                                                                                                    this.componentDidMount()


                                                                                                                }}
                                                                                                            >
                                                                                                            </Dialog>
                                                                                                            <FcApproval style={{ cursor: 'pointer', margin: 25, fontSize: 40 }}
                                                                                                                onClick={() => setState({ isShown: true })} />
                                                                                                        </Pane>
                                                                                                    )}
                                                                                                </Component>
                                                                                            </div> : <div></div>
                                                                                        }
                                                                                        {
                                                                                            item.calls == "لايرد" ? <div>

                                                                                                <Component initialState={{ isShown: false, notes: "" }}>
                                                                                                    {({ state, setState }) => (
                                                                                                        <Pane>
                                                                                                            <Dialog
                                                                                                                isShown={state.isShown}
                                                                                                                title="اضافة محاولة "
                                                                                                                intent="success"
                                                                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                                                                confirmLabel="تاكيد"
                                                                                                                cancelLabel='الغاء'
                                                                                                                onConfirm={() => {
                                                                                                                    this.add_call(item.id)
                                                                                                                    this.componentDidMount()
                                                                                                                    window.alert('تم معالجة الطلب')
                                                                                                                    setState({ isShown: false })
                                                                                                                }}
                                                                                                            >
                                                                                                            </Dialog>
                                                                                                            <FcAssistant style={{ cursor: 'pointer', margin: 25, fontSize: 40 }}
                                                                                                                onClick={() => setState({ isShown: true })} />
                                                                                                        </Pane>
                                                                                                    )}
                                                                                                </Component>
                                                                                            </div> :
                                                                                                <div>

                                                                                                </div>

                                                                                        }

                                                                                    </div>
                                                                                    :
                                                                                    <div></div>
                                                                            }
                                                                        </div>

                                                                        : <div>   </div>

                                                                }




                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>







                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }
                    }
                </Context.Consumer >
            </div >
        );
    }
}

export default Orders;